<template>
  <b-row class="lists page-collections">
    <b-col>
      <PageHeader :title="$t('general.collections.title')">
        <div class="page-collections__header">
          <button
            type="button"
            @click="toggleSearch"
            class="collections-header-button__button"
          >
            <CIcon name="search" class="collections-header-button__icon" />
          </button>
        </div>
      </PageHeader>

      <div class="page-collections__search" v-if="isSearch">
        <CInput
          ref="input"
          type="text"
          name="searchCollections"
          input-mode="text"
          theme="secondary"
          v-model="searchCollections"
          :placeholder="$t('general.collections.searchPlaceholder')"
        />
      </div>

      <div class="post-list">
        <div class="page-collections__tabs">
          <CRadio
            v-model="selectedTab"
            v-for="(item, index) in tabs"
            :key="`${index + '-tab'}`"
            :name="item.name"
            :value="item"
            group-name="user-tab"
            class="user-tab"
            @change="updateBookmarksList"
          >
            <template #default="{ isActive, value: tab }">
              <div
                class="user-tab__item"
                :class="{ 'user-tab__item--active': isActive }"
              >
                <span class="user-tab__font"> {{ tab.content }} </span>
              </div>
            </template>
          </CRadio>
        </div>
      </div>

      <template v-if="selectedTab.id === 'collections'">
        <transition name="fade" mode="out-in">
          <b-row class="d-block">
            <CollectionsGroupItem
              v-for="item in searchedList"
              :key="item.id"
              :group="item"
              class="page-collections__group"
              @find-collections="moveToCollections"
            />
          </b-row>
        </transition>
      </template>

      <template v-else>
        <transition name="fade" mode="out-in">
          <div class="post-list__posts">
            <InfinityScroll
              v-if="bookmarks && bookmarks.length"
              :items="bookmarks"
              :has-more="hasMorePosts"
              @rich-end="loadMore"
              is-grid
            >
              <template #default="{ item: bookmark }">
                <PostTile :post="bookmark" />
              </template>
            </InfinityScroll>
          </div>
        </transition>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import CIcon from "@/features/ui/CIcon.vue";
import PageHeader from "@/layout/PageHeader.vue";
import CollectionsGroupItem from "@/features/components/CollectionsGroupItem.vue";
import CRadio from "@/features/ui/CRadio.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";
import CInput from "@/features/ui/СInput.vue";

import { mapGetters, mapMutations } from "vuex";
import Post from "@/components/models/Post";

const bookmarksCallbackType = {
  setBookmarks: "setBookmarks",
  updateBookmarks: "updateBookmarks",
};
export default {
  name: "PageCollections",
  components: {
    CInput,
    PostTile,
    InfinityScroll,
    CRadio,
    CollectionsGroupItem,
    PageHeader,
    CIcon,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      lists: [],
      isSearch: false,
      searchCollections: "",
      selectedTab: {
        id: "collections",
        content: this.$t("general.collections.list"),
      },

      loadBookmarksCallback: {
        [bookmarksCallbackType.setBookmarks]: this.setCurrentPosts,
        [bookmarksCallbackType.updateBookmarks]: this.updateCurrentPosts,
      },
    };
  },
  mounted() {
    this.loadCollections().then(() => {
      this.initialLoadBookmarks();
    });
  },
  computed: {
    ...mapGetters({
      bookmarks: "bookmarks/bookmarks",
      bookmarksMeta: "bookmarks/bookmarksMeta",
      collection: "pageCollections/collection",
    }),

    tabs() {
      return [
        {
          id: "collections",
          content: this.$t("general.collections.list"),
        },
        {
          id: "bookmarks",
          content: this.$t("general.collections.savedPosts"),
        },
      ];
    },

    hasMorePosts() {
      return Boolean(this.bookmarksMeta?.next_page_url);
    },

    page() {
      return this.bookmarksMeta?.current_page || 1;
    },

    searchedList() {
      if (!this.searchCollections) return this.collection;

      return this.collection.filter((collection) =>
        collection.title.includes(this.searchCollections)
      );
    },
  },
  methods: {
    ...mapMutations({
      setBookmarks: "bookmarks/setBookmarks",
      updateBookmarks: "bookmarks/updateBookmarks",
      setMeta: "bookmarks/setMeta",
      setCollections: "pageCollections/setCollections",
    }),

    updateBookmarksList(tab) {
      if(tab.id === 'bookmarks') {
        this.initialLoadBookmarks();
      }
    },

    search() {
      console.log("search");
    },
    toggleSearch() {
      this.isSearch = !this.isSearch;
    },
    addNewCollection() {
      console.log("addNewCollection");
    },
    moveToCollections(collection) {
      console.log("moveToCollections", collection);
    },
    loadCollections() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$get(
          "/lists",
          (data) => {
            this.setCollections({ collection: data.lists });
          },
          (errors) => {
            console.log(errors);
          }
        );
      }).catch(this.checkErrors);
    },

    setCurrentPost(data) {
      this.setBookmarks({ bookmarks: [data] });
    },

    loadBookmarks(successCallbackType = bookmarksCallbackType.setBookmarks) {
      if (this.requestInProgress) return;

      const successCallback = this.loadBookmarksCallback[successCallbackType];
      const requestUrl = "/bookmarks";

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadBookmarks() {
      this.$showSpinner();

      this.loadBookmarks(bookmarksCallbackType.setBookmarks);
    },

    loadMore() {
      if (this.hasMoreBookmarks)
        return this.loadBookmarks(bookmarksCallbackType.updateBookmarks);
    },

    setCurrentPosts(data) {
      const { current_page, data: bookmarksList } = data;
      const bookmarks = bookmarksList.map(post => new Post(post))

      this.setBookmarks({ bookmarks });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const bookmarks = data.data;

      this.updateBookmarks({ bookmarks });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style scoped lang="scss">
.lists {
  a {
    text-decoration: none;
    color: var(--dark);

    &:hover {
      color: var(--dark);
      text-decoration: none;
      background: var(--light);
    }
  }
}

.page-collections {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__search {
    margin-top: em(16);
  }

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__group {
    margin: em(22) em(17);
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__posts {
    margin-top: 1px;
  }
}
</style>
