<template>
  <div>
    <div class="collections-group" @click="goTo">
      <div class="collections-group__content">
        <div>
          <div class="collections-group__title">
            <span
              class="collections-group__font collections-group__font--group-name"
            >
              {{ group.title }}
            </span>
          </div>
          <div class="collections-group__users">
            <span
              class="collections-group__font collections-group__font--users-count"
            >
              {{ $tc("general.x-people", [group.listees_count]) }}
            </span>
          </div>
        </div>
      </div>

      <span class="collections-group__addition">
        <button type="button" class="button">
          <CIcon
            name="arrow-menu-to-right"
            class="collections-group__icon collections-group__icon--arrow"
          />
        </button>
      </span>
    </div>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "CollectionsGroupItem",
  components: {
    CIcon,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  methods: {
    goTo() {
      this.$router.push({
        name: "selected-collection",
        params: { id: this.group.id },
        query: { title: this.group.title },
      });
      this.$emit("find-collections", this.group);
    },
  },
};
</script>

<style lang="scss" scoped>
.collections-group {
  $parent: &;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &__title {
  }

  &__users {
    margin-top: em(4);
  }

  &__font {
    color: $black;
    text-align: left;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    line-height: 1;

    &--group-name {
      color: $black;
      font-size: em(14);
      font-weight: 500;
    }

    &--users-count {
      color: $app-gray-18;
      font-style: normal;
      font-weight: 500;
      font-size: em(12);
    }
  }

  &__addition {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    display: inline-block;
    width: em(18);
    min-width: em(18);
    height: em(18);

    &--arrow {
      width: em(18);
      height: em(18);
      color: $app-gray-5;
    }
  }
}
</style>
